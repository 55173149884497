import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditInfluencerPending,
  selectFetchInfluencerVariablesInfoData,
  selectSelectedInfluencer,
} from '../../../../store/slices/influencer/slice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditInfluencerButton, LiveStreamNoteText, TimePickersRow } from './InfluencerEditTab.styles';
import {
  CHAT_TYPE,
  CHAT_TYPE_TITLE,
  PERSONA_STYLE,
  PERSONA_STYLE_TITLE,
  UPLOAD_FILE_CONSTRAINTS,
  VOICE_PROVIDER_TITLE,
} from '../../../../utils/constants';
import { editInfluencer } from '../../../../store/slices/influencer/asyncThunks';
import { notifyError, notifyInfo } from '../../../../utils/notify';
import { FlexFooter, FlexMain } from '../../../../components/utils/utils.styles';
import { editInfluencerFormSchema } from '../../../../forms/schemas/influencer.schema';
import { FormDatePicker, FormFileInput, FormInput, FormNumericInput } from '../../../../forms/form.styles';
import CropImageModal from '../../../../components/utils/modals/CropImageModal/CropImageModal';
import HandleVoiceProvidersModal from '../../../../components/modals/HandleVoiceProvidersModal/HandleVoiceProvidersModal';
import Select from '../../../../components/utils/selects/Select/Select';
import { FormCheckbox } from '../../../../forms/form.styles'; // Assuming you have a styled checkbox component
import { Switch } from '../../../../components';
import { addMinutes } from 'date-fns';

function InfluencerEditTab() {
  const selectedInfluencer = useSelector(selectSelectedInfluencer);
  const editInfluencerPending = useSelector(selectEditInfluencerPending);
  const cropImageRef = useRef();
  const handleVoiceProvidersModalRef = useRef();
  const dispatch = useDispatch();
  const influencerVariables = useSelector(selectFetchInfluencerVariablesInfoData);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      profilePhoto: selectedInfluencer.profilePhoto || null,
      name: selectedInfluencer.name || '',
      nickname: selectedInfluencer.nickname || '',
      email: selectedInfluencer.email || '',
      dateOfBirth: null,
      paymentRatio: selectedInfluencer.paymentRatio || 0.5,
      voiceResponseRatio: !isNaN(selectedInfluencer.voiceResponseRatio) ? selectedInfluencer.voiceResponseRatio : 0.5,
      telegramChannelUrl: selectedInfluencer.telegram.channelUrl || '',
      subscriptionPrice: selectedInfluencer.pricing.subscription || 400,
      starterThreshold: selectedInfluencer.starterThreshold || 10,
      cartesiaId: '',
      promptGroup: null,
      personality: selectedInfluencer.personality || '',
      timezone: null,
      personaStyle: null,
      chatType: null,
      massDMEnabled: selectedInfluencer.massDM?.enabled || false, // New field for Mass DM feature
      sleepingFrom: (() => {
        if (!selectedInfluencer.sleepingPeriod) return null;
        const sleepingFrom = new Date();
        sleepingFrom.setHours(selectedInfluencer.sleepingPeriod.from.hour);
        sleepingFrom.setMinutes(selectedInfluencer.sleepingPeriod.from.minute);
        return sleepingFrom;
      })(),
      sleepingUntil: (() => {
        if (!selectedInfluencer.sleepingPeriod) return null;
        const sleepingUntil = new Date();
        sleepingUntil.setHours(selectedInfluencer.sleepingPeriod.until.hour);
        sleepingUntil.setMinutes(selectedInfluencer.sleepingPeriod.until.minute);
        return sleepingUntil;
      })(),
      liveStreamFrom: null,
      liveStreamUntil: null,
      deleted: !!selectedInfluencer.deletedAt,
      rateLimit: selectedInfluencer.rateLimit,
    },
    delayError: 300,
    resolver: yupResolver(editInfluencerFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');
  const watchVoiceProviders = watch('voiceProviders');
  const watchSleepingFrom = watch('sleepingFrom');
  const watchLiveStreamFrom = watch('liveStreamFrom');

  useEffect(() => {
    if (selectedInfluencer) {
      const voiceProvider = selectedInfluencer.voiceProvider;
      setValue('voiceProviders.active', {
        value: voiceProvider.active,
        label: VOICE_PROVIDER_TITLE[voiceProvider.active],
      });
      if (selectedInfluencer.promptGroup) {
        setValue('promptGroup', {
          value: selectedInfluencer.promptGroup.id,
          label: selectedInfluencer.promptGroup.name,
        });
      }
      if (selectedInfluencer.chatType) {
        setValue('chatType', {
          value: selectedInfluencer.chatType,
          label: CHAT_TYPE_TITLE[selectedInfluencer.chatType],
        });
      }
      if (selectedInfluencer.personaStyle) {
        setValue('personaStyle', {
          value: selectedInfluencer.personaStyle,
          label: PERSONA_STYLE_TITLE[selectedInfluencer.personaStyle],
        });
      }
      if (selectedInfluencer.timezone) {
        setValue('timezone', {
          value: selectedInfluencer.timezone,
          label: selectedInfluencer.timezone,
        });
      }
      if (voiceProvider.cartesia) {
        setValue('cartesiaId', voiceProvider.cartesia.voiceId);
      }
      setValue('liveStreamFrom', null);
      setValue('liveStreamUntil', null);

      const utcDate = new Date(selectedInfluencer.dateOfBirth);
      const timezoneOffset = utcDate.getTimezoneOffset();
      const localDate = addMinutes(utcDate, timezoneOffset);
      setValue('dateOfBirth', localDate);
    }
  }, [selectedInfluencer, setValue]);

  const promptGroupOptions = useMemo(() => {
    if (!influencerVariables) return [];
    return influencerVariables.promptGroups.map(promptGroup => ({
      value: promptGroup.id,
      label: promptGroup.name,
    }));
  }, [influencerVariables]);

  const timezoneOptions = useMemo(() => {
    if (!influencerVariables) return [];
    return influencerVariables.timezones.map(timezone => ({
      value: timezone.id,
      label: timezone.name,
    }));
  }, [influencerVariables]);

  const chatTypeOptions = [
    {
      label: CHAT_TYPE_TITLE.FREE_CHAT,
      value: CHAT_TYPE.FREE_CHAT,
    },
    {
      label: CHAT_TYPE_TITLE.PAID_CHAT,
      value: CHAT_TYPE.PAID_CHAT,
    },
  ];

  const personaStyleOptions = [
    {
      label: PERSONA_STYLE_TITLE.BRAND_FRIENDLY,
      value: PERSONA_STYLE.BRAND_FRIENDLY,
    },
    {
      label: PERSONA_STYLE_TITLE.SEXUAL,
      value: PERSONA_STYLE.SEXUAL,
    },
  ];

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    dispatch(editInfluencer(data))
      .unwrap()
      .then(_ => {
        notifyInfo('Influencer updated successfully!');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <>
      <FlexMain>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="profilePhoto"
            control={control}
            render={({ field }) => {
              return (
                <FormFileInput
                  label="Profile photo"
                  value={field.value}
                  onChange={field.onChange}
                  type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                  error={errors.profilePhoto?.message}
                />
              );
            }}
          />
          <FormInput label="Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
          <Controller
            name="promptGroup"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Prompt Group"
                  selectedOption={field.value}
                  handleChange={field.onChange}
                  options={promptGroupOptions}
                  isClearable
                />
              );
            }}
          />
          <TimePickersRow>
            <Controller
              name="sleepingFrom"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Sleeping from"
                    placeholder="Enter sleeping from"
                    value={field.value}
                    onChange={value => {
                      if (!value) {
                        setValue('sleepingUntil', null);
                      }

                      field.onChange(value);
                    }}
                    error={errors.sleepingFrom?.message}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                  />
                );
              }}
            />
            <Controller
              name="sleepingUntil"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Sleeping until"
                    placeholder="Enter sleeping until"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.sleepingUntil?.message}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    disabled={!watchSleepingFrom}
                  />
                );
              }}
            />
          </TimePickersRow>
          <TimePickersRow>
            <Controller
              name="liveStreamFrom"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Live stream from"
                    placeholder="Enter live stream from"
                    value={field.value}
                    onChange={value => {
                      if (!value) {
                        setValue('liveStreamUntil', null);
                      }

                      field.onChange(value);
                    }}
                    error={errors.liveStreamFrom?.message}
                    minDate={new Date()}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm"
                  />
                );
              }}
            />
            <Controller
              name="liveStreamUntil"
              control={control}
              render={({ field }) => {
                return (
                  <FormDatePicker
                    label="Live stream until"
                    placeholder="Enter live stream until"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.liveStreamUntil?.message}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm"
                    disabled={!watchLiveStreamFrom}
                    minDate={watchLiveStreamFrom}
                  />
                );
              }}
            />
          </TimePickersRow>
          {selectedInfluencer.liveStream && (
            <LiveStreamNoteText>
              Note: Live stream already scheduled from {new Date(selectedInfluencer.liveStream.from).toLocaleString()}{' '}
              until {new Date(selectedInfluencer.liveStream.until).toLocaleString()}
            </LiveStreamNoteText>
          )}

          <FormInput
            label="Personality"
            placeholder="Enter personality"
            {...register('personality')}
            error={errors.personality?.message}
            type="textarea"
          />
          <FormInput
            label="Nickname"
            placeholder="Enter nickname"
            {...register('nickname')}
            error={errors.nickname?.message}
          />
          <FormInput label="Email" placeholder="Enter email" {...register('email')} error={errors.email?.message} />
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => {
              return (
                <FormDatePicker
                  label="Date of birth"
                  placeholder="Enter date of birth"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.dateOfBirth?.message}
                />
              );
            }}
          />
          <FormInput
            label="Telegram Channel URL"
            placeholder="Enter Telegram Channel URL"
            {...register('telegramChannelUrl')}
            error={errors.telegramChannelUrl?.message}
          />
          <FormInput
            label="Cartesia ID"
            placeholder="Cartesia ID"
            {...register('cartesiaId')}
            error={errors.cartesiaId?.message}
          />
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Timezone"
                  selectedOption={field.value}
                  handleChange={field.onChange}
                  options={timezoneOptions}
                  error={errors.timezone?.message}
                />
              );
            }}
          />
          <Controller
            name="personaStyle"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Persona Style"
                  selectedOption={field.value}
                  handleChange={field.onChange}
                  options={personaStyleOptions}
                  error={errors.personaStyle?.message}
                />
              );
            }}
          />
          <Controller
            name="chatType"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Chat Type"
                  selectedOption={field.value}
                  handleChange={field.onChange}
                  options={chatTypeOptions}
                  error={errors.chatType?.message}
                />
              );
            }}
          />
          <FormNumericInput
            label="Payment ratio"
            {...register('paymentRatio', {
              valueAsNumber: true,
            })}
            error={errors.paymentRatio?.message}
          />
          <FormNumericInput
            label="Voice response ratio"
            {...register('voiceResponseRatio', {
              valueAsNumber: true,
            })}
            error={errors.voiceResponseRatio?.message}
          />
          <FormNumericInput
            label="Subscription Price"
            {...register('subscriptionPrice', {
              valueAsNumber: true,
            })}
            error={errors.subscriptionPrice?.message}
          />
          <FormNumericInput
            label="Starter Threshold"
            {...register('starterThreshold', {
              valueAsNumber: true,
            })}
            error={errors.starterThreshold?.message}
          />
          <FormNumericInput
            label="Rate Limit"
            {...register('rateLimit', {
              valueAsNumber: true,
            })}
            error={errors.rateLimit?.message}
          />
          <Controller
            name="massDMEnabled"
            control={control}
            render={({ field }) => (
              <FormCheckbox
                label="Enable Mass DM"
                checked={field.value}
                onChange={field.onChange}
                error={errors.massDMEnabled?.message}
              />
            )}
          />
          <Controller
            name="deleted"
            control={control}
            render={({ field }) => <Switch selectedValue={field.value} onChange={field.onChange} label="Deleted" />}
          />
        </form>
      </FlexMain>
      <FlexFooter>
        <EditInfluencerButton
          title="Edit influencer"
          onClick={() => handleSubmit(onSubmit)()}
          isLoading={editInfluencerPending}
        />
      </FlexFooter>
      <HandleVoiceProvidersModal
        modalRef={handleVoiceProvidersModalRef}
        onSetVoiceProviders={data => setValue('voiceProviders', data, { shouldValidate: true })}
        voiceProviders={watchVoiceProviders}
      />
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </>
  );
}

export default InfluencerEditTab;
