import * as yup from 'yup';
import { VOICE_PROVIDER } from '../../utils/constants';
import { addHours } from 'date-fns';

export const createInfluencerStepBasicInfoFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    nickname: yup.string().trim().required('Nickname is required').max(255, 'Max length reached'),
    username: yup.string().trim().required('Username is required').max(255, 'Max length reached'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    password: yup.string().when('influencerRequestId', {
      is: influencerRequestId => !influencerRequestId,
      then: () =>
        yup.string().trim().required('Password is required').min(6, 'Password must have at least 6 characters'),
    }),
    passwordConfirmation: yup.string().when('influencerRequestId', {
      is: influencerRequestId => !influencerRequestId,
      then: () =>
        yup
          .string()
          .trim()
          .required('Password confirmation is required')
          .oneOf([yup.ref('password'), ''], "Passwords don't match!"),
    }),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    sleepingUntil: yup
      .date()
      .nullable()
      .when('sleepingFrom', {
        is: sleepingFrom => !!sleepingFrom,
        then: () => yup.date().required('Sleeping until is required'),
      }),
    profilePhoto: yup.mixed().required('Profile photo is required'),
    businessConnection: yup.mixed().required('Business connection is required'),
    paymentRatio: yup
      .number()
      .typeError('Payment ratio must be a number')
      .required('Payment ratio is required')
      .min(0, 'Payment ratio cannot be below 0')
      .max(10, 'Payment ratio cannot be above 10'),
    voiceResponseRatio: yup
      .number()
      .typeError('Voice response ratio must be a number')
      .required('Voice response ratio is required')
      .min(0, 'Voice response ratio cannot be below 0')
      .max(1, 'Voice response ratio cannot be above 1'),
    subscriptionPrice: yup
      .number()
      .typeError('Subscription price must be a number')
      .required('Subscription price is required')
      .min(0.001, 'Subscription price must be above 0'),
    starterThreshold: yup
      .number()
      .typeError('Starter threshold must be a number')
      .integer('Starter threshold must be an integer')
      .required('Starter threshold is required')
      .min(1, 'Starter threshold must be at least 1'),
    cartesiaId: yup.string().trim().required('Cartesia ID is required'),
    personality: yup.string().trim().required('Personality is required').max(2000, 'Max length reached'),
    timezone: yup.mixed().required('Timezone is required'),
    personaStyle: yup.mixed().required('Persona style is required'),
    chatType: yup.mixed().required('Chat type is required'),
    rateLimit: yup
      .number()
      .typeError('Rate limit must be a number')
      .integer('Rate limit must be an integer')
      .required('Rate limit is required')
      .min(1, 'Rate limit must be at least 1'),
  })
  .required();

export const editInfluencerFormSchema = yup
  .object({
    profilePhoto: yup.mixed().required('Profile photo is required'),
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    nickname: yup.string().trim().required('Nickname is required').max(255, 'Max length reached'),
    email: yup.string().trim().required('Email is required').email('Invalid email'),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    sleepingUntil: yup
      .date()
      .nullable()
      .when('sleepingFrom', {
        is: sleepingFrom => !!sleepingFrom,
        then: () => yup.date().required('Sleeping until is required'),
      }),
    liveStreamFrom: yup.date().nullable(),
    // .min(addHours(new Date(), 12), 'Please select a date at least 12 hours from now'),
    liveStreamUntil: yup
      .date()
      .nullable()
      .when('liveStreamFrom', (liveStreamFrom, schema) => {
        if (liveStreamFrom[0]) {
          const oneHourAfter = addHours(liveStreamFrom[0], 1);
          const sixHoursAfter = addHours(liveStreamFrom[0], 6);

          return schema
            .required('Live stream until is required')
            .min(oneHourAfter, 'Live stream must be at least 1 hour')
            .max(sixHoursAfter, 'Live stream must be at most 6 hours');
        } else {
          return schema;
        }
      }),
    paymentRatio: yup
      .number()
      .typeError('Payment ratio must be a number')
      .required('Payment ratio is required')
      .min(0, 'Payment ratio cannot be below 0')
      .max(10, 'Payment ratio cannot be above 10'),
    voiceResponseRatio: yup
      .number()
      .typeError('Voice response ratio must be a number')
      .required('Voice response ratio is required')
      .min(0, 'Voice response ratio cannot be below 0')
      .max(1, 'Voice response ratio cannot be above 1'),
    subscriptionPrice: yup
      .number()
      .typeError('Subscription price must be a number')
      .required('Subscription price is required')
      .min(0.001, 'Subscription price must be above 0'),
    starterThreshold: yup
      .number()
      .typeError('Starter threshold must be a number')
      .integer('Starter threshold must be an integer')
      .required('Starter threshold is required')
      .min(1, 'Starter threshold must be at least 1'),
    cartesiaId: yup.string().trim().required('Cartesia ID is required'),
    timezone: yup.mixed().required('Timezone is required'),
    personaStyle: yup.mixed().required('Persona style is required'),
    chatType: yup.mixed().required('Chat type is required'),
    personality: yup.string().trim().required('Personality is required').max(2000, 'Max length reached'),
    rateLimit: yup
      .number()
      .typeError('Rate limit must be a number')
      .integer('Rate limit must be an integer')
      .required('Rate limit is required')
      .min(1, 'Rate limit must be at least 1'),
  })
  .required();

export const addVoiceProviderFormSchema = yup
  .object({
    active: yup.mixed().required('Please select a voice provider'),
    cartesia: yup.object().when('active', {
      is: ({ value }) => {
        return value === VOICE_PROVIDER.CARTESIA;
      },
      then: () =>
        yup
          .object({
            voiceId: yup.string().trim().required('Voice ID is required'),
          })
          .required(),
      otherwise: () => yup.object().notRequired(),
    }),
    elevenLabs: yup.object().when('active', {
      is: ({ value }) => {
        return value === VOICE_PROVIDER.ELEVEN_LABS;
      },
      then: () =>
        yup
          .object({
            voiceId: yup.string().trim().required('Voice ID is required'),
            voiceConfig: yup.object({
              stability: yup
                .number()
                .typeError('Stability must be a number')
                .min(0, 'Stability must be number between 0 and 1')
                .max(1, 'Stability must be number between 0 and 1')
                .required('Stability is required'),
              similarityBoost: yup
                .number()
                .typeError('Similarity boost must be a number')
                .min(0, 'Similarity boost must be number between 0 and 1')
                .max(1, 'Similarity boost must be number between 0 and 1')
                .required('Similarity boost is required'),
              style: yup
                .number()
                .typeError('Style must be a number')
                .min(0, 'Style must be number between 0 and 1')
                .max(1, 'Style must be number between 0 and 1')
                .required('Style is required'),
            }),
          })
          .required(),
      otherwise: () => yup.object().notRequired(),
    }),
  })
  .required();
