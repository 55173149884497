import React, { Fragment } from 'react';
import { TableBodyItem, TableItemImage } from '../../Table.styles';

function InfluencerTableItem({ name, profilePhoto, username, email, isDeleted }) {
  return (
    <Fragment>
      <TableBodyItem $isDeleted={isDeleted}>
        <TableItemImage src={profilePhoto} alt="influencer-profile" />
        <p>{name || '-'}</p>
      </TableBodyItem>
      <TableBodyItem $isDeleted={isDeleted}>
        <p>{username || '-'}</p>
      </TableBodyItem>
      <TableBodyItem $isDeleted={isDeleted}>
        <p>{email || '-'}</p>
      </TableBodyItem>
    </Fragment>
  );
}

export default InfluencerTableItem;
